function updateTabForms(group: string, selected: string) {
  for (const form of document.querySelectorAll(`.${group}-form`)) {
    console.log(form.getAttribute("id"), group + "-" + selected + "-" + form);
    if (form.getAttribute("id") === group + "-" + selected + "-form") {
      form.classList.remove("hidden");
    } else {
      form.classList.add("hidden");
    }
  }
}

function init() {
  const form = document.querySelector(".donate-form");
  if (!form) return;

  for (const radio of form.querySelectorAll<HTMLInputElement>(
    'input[name="method"]'
  )) {
    radio.addEventListener("change", (e) => {
      if (radio.checked) {
        updateTabForms("method", radio.value);
      }
    });
  }
  updateTabForms("method", "sepa");

  for (const radio of form.querySelectorAll<HTMLInputElement>(
    'input[name="sepa_type"]'
  )) {
    radio.addEventListener("change", (e) => {
      if (radio.checked) {
        updateTabForms("sepa_type", radio.value);
      }
    });
  }
  updateTabForms("sepa_type", "iban");
}
init();

export {};
