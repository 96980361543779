import { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { nanoid } from "nanoid";
import { retailers } from "./payloads";
import { autocomplete } from "@algolia/autocomplete-js";
import "@algolia/autocomplete-theme-classic";

interface Category {
  title: string;
  catId: number;
}

interface AltCatResponse {
  categories?: Category[];
}

interface Box<T> {
  done: boolean;
  failed: boolean;
  current?: T;
}

function useBox<T>(cb: () => Promise<T>): Box<T> {
  const [box, setBox] = useState<Box<T>>({
    done: false,
    failed: false,
  });
  const started = useRef(false);

  if (!started.current) {
    started.current = true;
    cb()
      .then((value) => setBox({ done: true, failed: false, current: value }))
      .catch((e) => setBox({ done: true, failed: true }));
  }

  return box;
}

async function fetchAltCats(): Promise<Category[]> {
  try {
    const response = await fetch(
      "https://www.replaceplastic.de/api/alt-categories",
      {
        credentials: "omit",
      }
    );
    if (response.status === 200) {
      const result = (await response.json()) as AltCatResponse;
      if (result.categories) {
        return result.categories;
      }
    }
  } catch (e) {
    console.error("Failed to fetch categories", e);
  }

  return [];
}

interface Product {
  type: "title" | "product";
  name: string;
  vendor?: string;
  vendorMail?: string;
  barcode?: string;
}

interface AltCatItemsResponse {
  alternatives?: Product[];
}

async function loadProductsForCat(
  catId: number,
  setProducts: (value: Product[]) => void
): Promise<void> {
  try {
    const response = await fetch(
      "https://www.replaceplastic.de/api/alt-cat-items",
      {
        method: "POST",
        credentials: "omit",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ catId }),
      }
    );
    if (response.status === 200) {
      setProducts(
        ((await response.json()) as AltCatItemsResponse).alternatives ?? []
      );
      return;
    }
  } catch (e) {
    console.error("Failed to fetch products", e);
  }

  setProducts([]);
}

function ProductBox(props: {
  product: Product;
  onRequest: () => void;
}): React.ReactElement {
  return (
    <div className="p-2 mb-2 border border-white text-white">
      <strong>Name: </strong>
      {props.product.name}
      <br />
      <strong>Anbieter: </strong>
      {props.product.vendor}
      <br />
      <button className="text-white bg-orange p-2" onClick={props.onRequest}>
        Das wünsche ich mir in meinem Markt
      </button>
    </div>
  );
}

interface CategorySectionProps {
  catId: number;
  title: string;
  defaultOpen?: boolean;
  onRequestProduct: (product: Product) => void;
}
function CategorySection(props: CategorySectionProps): React.ReactElement {
  const [open, setOpen] = useState(!!props.defaultOpen);
  const [items, setItems] = useState<Product[]>([]);
  const loading = useRef(false);

  useEffect(() => {
    if (open && items.length === 0 && !loading.current) {
      loading.current = true;
      void loadProductsForCat(props.catId, setItems);
    }
  }, [open]);

  return (
    <div>
      <div
        className="p-4 text-white bg-light cursor-pointer border border-white"
        onClick={() => setOpen(!open)}
      >
        {props.title}
      </div>
      {open ? (
        items.length === 0 ? (
          <div>Lade...</div>
        ) : (
          <div className="bg-sky-1 p-2">
            {items.map((item) =>
              item.type === "product" ? (
                <ProductBox
                  key={item.barcode}
                  product={item}
                  onRequest={() => props.onRequestProduct(item)}
                />
              ) : null
            )}
          </div>
        )
      ) : null}
    </div>
  );
}

interface ProductSearchResult {
  products?: Product[];
}

async function searchAltProducts(
  query: string,
  setProducts: (value: Product[]) => void
): Promise<void> {
  try {
    const response = await fetch(
      "https://www.replaceplastic.de/api/alt-search",
      {
        method: "POST",
        credentials: "omit",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ query }),
      }
    );
    if (response.status === 200) {
      const result = (await response.json()) as ProductSearchResult;
      if (result.products) {
        setProducts(result.products);
        return;
      }
    }
  } catch (e) {
    console.error("Failed to search products", e);
  }

  setProducts([]);
}

function SearchForm(): React.ReactElement {
  const [query, setQuery] = useState(() =>
    decodeURIComponent(location.search.replace(/^\?q=/, ""))
  );
  const cats = useBox(fetchAltCats);
  const [productResults, setProductResults] = useState<Product[]>([]);
  const [requestedProduct, setRequestedProduct] = useState<Product>();

  function search(query: string): void {
    searchAltProducts(query, setProductResults);
  }

  const matchedCats = [];
  if (cats.done) {
    const lowerQuery = query.toLowerCase();
    for (const cat of cats.current ?? []) {
      if (cat.title.toLowerCase().indexOf(lowerQuery) > -1)
        matchedCats.push(cat);
    }
  }

  useEffect(() => {
    if (query !== "") {
      search(query);
    }
  }, []);

  return requestedProduct ? (
    <AltRequestForm product={requestedProduct} />
  ) : (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          search(query);
        }}
      >
        <input
          className="p-2 mb-2 rounded-md border border-bodytext shadow-inner shadow-lightgray w-full"
          type="text"
          value={query}
          placeholder="Gib eine Kategorie ein, z.B. Nudeln"
          onChange={(e) => setQuery(e.target.value)}
        />
      </form>
      <div>
        {matchedCats.map((item) => (
          <CategorySection
            key={item.catId}
            defaultOpen={matchedCats.length === 1}
            {...item}
            onRequestProduct={setRequestedProduct}
          />
        ))}
        <div className="p-2 bg-light">
          {productResults.map((item) => {
            return (
              <ProductBox
                key={item.barcode}
                product={item}
                onRequest={() => setRequestedProduct(item)}
              />
            );
          })}
        </div>
      </div>
    </>
  );
}

const countries = ["D", "A", "CH"];
function AltRequestForm({ product }: { product: Product }): React.ReactElement {
  const [country, setCountry] = useState("D");
  const [zip, setZip] = useState("");
  const [retailer, setRetailer] = useState("");
  const [sending, setSending] = useState(false);
  const [done, setDone] = useState(false);
  const [error, setError] = useState(false);

  function send() {
    setSending(true);
    void (async () => {
      try {
        const response = await fetch(
          "https://www.replaceplastic.de/api/alt-request",
          {
            method: "POST",
            credentials: "omit",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              transact: nanoid(16),
              bundle: nanoid(16),
              barcode: product.barcode,
              country,
              zipCode: zip,
              retailer,
            }),
          }
        );
        if (response.status === 200) {
          setDone(true);
          return;
        }
      } catch (e) {
        console.error("Failed to send alt request", e);
      }

      setError(true);
    })();
  }

  return error ? (
    <>
      <h2 className="font-title text-xl">Das ist leider schiefgegangen</h2>
      <div>
        Aufgrund eines Fehlers konnte der Listungswunsch leider nicht abgesendet
        werden.
      </div>
      <button
        className="text-white bg-orange p-2"
        onClick={() => {
          setError(false);
          send();
        }}
      >
        Erneut versuchen
      </button>
    </>
  ) : done ? (
    <>
      <h2 className="font-title text-xl">Danke für dein Feedback</h2>
      <div>
        Gemeinsam schaffen wir eine spürbare Nachfrage nach plastikfreien
        Verpackungen, die auch bei den Handelsketten ankommt. Wir geben deinen
        Wunsch an das Unternehmen weiter. Es wird in der Regel nach 14 Tagen
        über die gesammelten Kundenwünsche benachrichtigt.
      </div>
    </>
  ) : sending ? (
    <>
      <h2 className="font-title text-xl">Danke für dein Feedback</h2>
      <div>Bitte warten...</div>
    </>
  ) : (
    <>
      <div>
        Wir glauben, dass es wichtig ist, den großen Handelsketten mitzuteilen,
        dass wir uns plastikfrei verpackte Produkte in ihrem Sortiment wünschen.
        Hier kannst du deinen Wunsch für ein gewähltes Produkt an eine der
        ausgewählten Ketten senden. Wir sammeln die Wünsche 14 Tage lang und
        senden sie dann gebündelt an das Handelsunternehmen.
        <br />
        Gleichzeitig senden wir deinen Wunsch als ein positives Feedback an das
        produzierende Unternehmen. Es ist wichtig, dass Unternehmen, die sich
        die Plastikfrage zu Herzen nehmen, erfahren, dass ihre Bemühungen von
        uns gesehen werden. Wir hoffen, dass diese Informationen dem Unternehmen
        bei Verhandlungen mit den Handelsketten helfen können.
      </div>

      <div className="p-2 mt-4 mb-4 border border-white text-white bg-sky-1">
        <strong>Name: </strong>
        {product.name}
        <br />
        <strong>Anbieter: </strong>
        {product.vendor}
        <br />
        <strong>Barcode: </strong>
        {product.barcode}
        <br />
      </div>

      <form onSubmit={() => void 0}>
        Land:
        <br />
        <div className="flex flex-row">
          {countries.map((item) => (
            <label className="flex-1" key={item}>
              <input
                className="mr-2"
                type="radio"
                name="country"
                value={item}
                checked={country === item}
                onChange={() => setCountry(item)}
              />
              {item}
            </label>
          ))}
        </div>
        Postleitzahl (optional):
        <br />
        <input
          className="w-full my-2 p-1 rounded-md border border-dark shadow-inner shadow-input-shadow"
          type="text"
          value={zip}
          onChange={(e) => setZip(e.target.value)}
        />
        <br />
        Markt: <br />
        {((retailers as Record<string, string>)[country] ?? "")
          .split(",")
          .map((item) => (
            <label key={item}>
              <input
                key={item}
                type="radio"
                value={item}
                checked={retailer === item}
                onChange={() => setRetailer(item)}
              />{" "}
              {item}
              <br />
            </label>
          ))}
        <button className="text-white bg-orange p-2 mt-2 mb-4" onClick={send}>
          Listungswunsch senden
        </button>
      </form>
      <h3 className="font-title text-xl">Dein Supermarkt ist nicht dabei?</h3>
      <button
        className="text-white bg-orange p-2 mt-2"
        onClick={() => {
          location.href = `mailto:?body=${mailText(product).replace(
            /\\n/g,
            "\n"
          )}`;
        }}
      >
        Selbst eine Mail an ein Unternehmen senden
      </button>
    </>
  );
}

const mailText = (product: Product): string =>
  `
Sehr geehrte Damen und Herren,\\n
ich wende mich heute an Sie, weil ich dieses oder ein gleichartiges plastikfrei oder mit einer Mehrweglösung verpackte Produkt in Ihrem Markt kaufen können möchte:\\n
*	${product.barcode} ${product.name}\\n
\\n
Plastikmüll in den Meeren stellt ein großes Problem dar, weshalb immer mehr Verbraucher ein Bewusstsein für dieses Thema zeigen. Viele Menschen wünschen sich plastikfreie Verpackungen. Auch ich gehöre dazu. Aus diesem Grund sende ich Ihnen heute den Wunsch, ein solche Produkte in Ihrem Markt kaufen können.\\n
Ich hoffe, dass diese Information über die Wünsche und Werte Ihrer Zielgruppe für Sie hilfreich ist, um bessere Lösungen für Ihre Kunden zu verwirklichen.\\n
Mit freundlichen Grüßen, Ihr Kunde`;

const searchForm = document.querySelector("#search-form");
if (searchForm) {
  ReactDOM.createRoot(searchForm).render(<SearchForm />);
}

const frontSearchInput = document.querySelector<HTMLInputElement>(
  ".front-search-autocomplete"
);
if (frontSearchInput) {
  const form = frontSearchInput.parentNode as HTMLFormElement;
  const formURL = form.getAttribute("action");
  const altCats = fetchAltCats();

  autocomplete({
    container: form,
    placeholder: "Gib eine Kategorie ein, z.B. Nudeln",
    async getSources({ query }) {
      const cats = await altCats;
      return [
        {
          sourceId: "cats",
          getItems() {
            const items = [];
            const q = query.toLowerCase();
            for (const cat of cats) {
              if (cat.title.toLowerCase().indexOf(q) > -1) {
                items.push(cat);
              }
            }
            return items;
          },
          getItemUrl({ item }) {
            return `${formURL}?q=${encodeURIComponent(item.title as string)}`;
          },
          onSelect(params) {
            if (params.itemUrl) {
              location.href = params.itemUrl;
            }
          },
          templates: {
            item({ item, components, html }) {
              return html`<div class="aa-ItemWrapper">
                <div class="aa-ItemContent">
                  <div class="aa-ItemContentBody">
                    <div class="aa-itemContentTitle">
                      ${components.Highlight({ hit: item, attribute: "title" })}
                    </div>
                  </div>
                </div>
              </div>`;
            },
          },
        },
      ];
    },
  });

  form.removeChild(frontSearchInput);
}
