if (window.IntersectionObserver) {
  const observer = new IntersectionObserver((entries) => {
    for (const entry of entries) {
      const cls = entry.target.classList;
      if (entry.isIntersecting || entry.boundingClientRect.bottom < (entry.rootBounds?.top ?? 0)) {
        if (!cls.contains("scroll-visible")) {
          cls.add("scroll-visible");
        }
      } else {
        if (cls.contains("scroll-visible")) {
          cls.remove("scroll-visible");
        }
      }
    }
  });

  for (const node of document.querySelectorAll(".scroll-effect")) {
    observer.observe(node);
  }
} else {
  for (const node of document.querySelectorAll(".scroll-effect")) {
    node.classList.remove("scroll-effect");
  }
}

export {};
