interface Product {
  barcode: string;
  count: number;
  created: string;
  createdAt: string;
  detailname: string;
  id: number;
  name: string;
  vendor: string;
}

interface Mail {
  productCount: number;
  productTitles: string[];
  sentTimeISO: string;
  vendorName: string;
}

interface Vendor {
  count: number;
  data: string;
}

interface StatsResponse {
  entry_count: number;
  legacy_mails_sent: number;
  mails_sent: number;
  product_count: number;
  vendor_count: number;
  latest_products: Product[];
  latest_alts: Product[];
  mails_details: Mail[];
  top_vendors: Vendor[];
  alt_count: number;
  recycle_count: number;
}

function t(content: string): Text {
  return document.createTextNode(content);
}

function h(tag: string, cls: string, ...nodes: Node[]): Element {
  const el = document.createElement(tag);
  if (cls) {
    el.setAttribute("class", cls);
  }

  for (const item of nodes) {
    el.appendChild(item);
  }
  return el;
}

function ten(num: number): string {
  let result = String(num);
  if (num < 10) result = "0" + result;
  return result;
}

function renderProduct(product: Product): Element {
  const moment = new Date(product.created);
  return h(
    "div",
    "m-2 p-2 border-white border flex shadow-md bg-light",
    h(
      "div",
      "w-20 bg-sky-2 flex-none flex flex-col justify-center text-sm",
      h(
        "div",
        "",
        t(
          ten(moment.getDate()) +
            "." +
            ten(moment.getMonth() + 1) +
            "." +
            moment.getFullYear()
        ),
        h("br", ""),
        h(
          "span",
          "text-lg font-bold",
          t(ten(moment.getHours()) + ":" + ten(moment.getMinutes()))
        )
      )
    ),
    h(
      "div",
      "flex-1 ml-2 text-left leading-tight overflow-hidden",
      h(
        "div",
        "text-lg font-bold overflow-hidden text-ellipsis whitespace-nowrap block",
        t(product.detailname)
      ),
      h("div", "", h("span", "font-bold", t("Anbieter: ")), t(product.vendor)),
      h("div", "", h("span", "font-bold", t("Barcode: ")), t(product.barcode))
    )
  );
}

function humanizeNumber(value: number | undefined) {
  let result = String(value || "0");
  const l = result.length;
  for (let i = 3; i < l; i += 3) {
    result = result.substring(0, l - i) + "." + result.substring(l - i);
  }

  return result;
}

async function initStats(): Promise<void> {
  const counters: Record<string, Element> = {};
  const names = ["scans", "mails", "products", "vendors", "alts", "recycles"];

  for (const name of names) {
    const el = document.querySelector("#stats-" + name);
    if (!el) return;
    counters[name] = el;
    el.innerHTML = "0";
  }

  let response: StatsResponse;
  try {
    const result = await fetch("https://www.replaceplastic.de/api/stats/", {
      credentials: "omit",
    });
    response = await result.json();
  } catch (e) {
    console.error(e);

    for (const name of names) {
      counters[name].innerHTML = "?";
    }
    return;
  }

  const prodList = document.querySelector("#stats-latest-products");
  if (prodList) {
    for (const product of response.latest_products.slice(0, 5)) {
      prodList.appendChild(renderProduct(product));
    }
  }

  const altList = document.querySelector("#stats-latest-alts");
  if (altList) {
    for (const product of response.latest_alts.slice(0, 5)) {
      altList.appendChild(renderProduct(product));
    }
  }

  const topVendors = document.querySelector("#stats-top-vendors");
  if (topVendors) {
    const places: Record<number, boolean> = {};

    for (const vendor of response.top_vendors.slice(0, 10)) {
      let idx: number;
      while (true) {
        idx = Math.floor(Math.random() * response.top_vendors.length);
        if (!places[idx]) {
          places[idx] = true;
          break;
        }
      }

      const item = h("div", "", t(vendor.data));
      item.setAttribute("style", `font-size: ${idx * 0.5 + 8}px`);
      topVendors.appendChild(item);
    }
  }

  if (window.IntersectionObserver) {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setTimeout(() => playAnimation(counters, response), 500);
        observer.disconnect();
      }
    });
    const container = document.querySelector('.stats-container');
    if (container !== null) {
      observer.observe(container);
    }
  } else {
    playAnimation(counters, response);
  }
}

function playAnimation(counters: Record<string, Element>, response: StatsResponse): void {
  const pairs: [Element, number][] = [
    [counters.scans, response.entry_count],
    [counters.mails, response.mails_sent + response.legacy_mails_sent],
    [counters.products, response.product_count],
    [counters.vendors, response.vendor_count],
    [counters.alts, response.alt_count],
    [counters.recycles, response.recycle_count],
  ];
  const start = Date.now();
  const duration = 3000;

  function tick() {
    const dist = (Date.now() - start) / duration;
    const scale = Math.min(1, 1 - Math.pow(1 - dist, 4));
    for (const [el, target] of pairs) {
      el.innerHTML = humanizeNumber(Math.round(target * scale));
    }

    if (dist < 1) {
      requestAnimationFrame(tick);
    } else {
      for (const [el, target] of pairs) {
        el.innerHTML = humanizeNumber(target);
      }
    }
  }
  tick();
}

void initStats();

export {};
