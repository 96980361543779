import Swiper from 'swiper';
import 'swiper/css/bundle';
import SwiperCore, { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/core';

SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade]);

if (document.querySelector('.swiper-container') !== null) {
  try {
    const swiper = new Swiper('.swiper-container', {
      slidesPerView: 'auto',
      autoplay: {
        delay: 6000,
      },
      loop: true,
      speed: 800,
      effect: 'slide',
      // If we need pagination
      pagination: {
        el: '.swiper-pagination',
      },

      // Navigation arrows
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });

    swiper.autoplay.start();
  } catch(e) {
    console.error(e);
  }
}
