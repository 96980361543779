function init() {
  for (const title of document.querySelectorAll(".collapsible-container h4")) {
    const content = title.nextElementSibling as HTMLDivElement;
    const link = document.createElement('button');
    link.setAttribute('class', 'w-full text-left');
    link.addEventListener('click', (e) => {
      e.preventDefault();
      if (content.classList.contains('open')) {
        content.classList.remove('open');
        link.classList.remove('open');
      } else {
        content.classList.add('open');
        link.classList.add('open');
      }
    });

    title.replaceWith(link);
    link.appendChild(title);
    content.classList.remove('open');
  }
}
init();

export {};
