import '@podlove/web-player/embed.js';
import '@podlove/web-player/variant-xl.js';
import '@podlove/web-player/variant-m.js';

interface ShowInfo {
  title: string;
  subtitle: string;
  summary: string;
  poster: string;
  link?: string;
}

interface EpisodeInfo {
  ep: {
    title: string;
    subtitle?: string;
    summary: string;
    publicationDate: string;
    duration: string;
    poster?: string;
    link?: string;
  };
  audio: {
    url: string;
    size?: string;
  }[];
}

// @ts-expect-error window....
window.initPodcastPlayer = function init(sel: string, basePath: string, showInfo: ShowInfo, epInfo: EpisodeInfo) {
  // @ts-expect-error missing types
  window.podlovePlayer(sel, {
    version: 5,
    show: showInfo,
    ...epInfo.ep,
    audio: epInfo.audio.map(item => ({
      ...item,
      title: 'MP3 Audio (mp3)',
      mimeType: 'audio/mpeg',
    })),
  }, {
    version: 5,
    base: basePath,
  });
}

export {}
