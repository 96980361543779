const ibanInput = document.querySelector<HTMLInputElement>('input[name=iban]');
const ibanError = document.querySelector<HTMLDivElement>('.iban-error');
const bicInput = document.querySelector<HTMLInputElement>('input[name=bic]');
const bankLabel = document.querySelector<HTMLDivElement>('.bank-label');
const submitBtn = document.querySelector<HTMLButtonElement>('form button');

interface BICInfo {
  found: boolean;
  bic?: string;
  name?: string;
}

function validateIBAN(input: string): boolean {
  // If the browser doesn't support BigInt, don't bother and just skip this check.
  // @ts-expect-error TS doesn't know about BigInt
  if (!window.BigInt) return true;

  let checkInput = input.substring(4);

  // convert country code
  const aOffset = 'A'.charCodeAt(0);
  checkInput += input.charCodeAt(0) - aOffset + 10;
  checkInput += input.charCodeAt(1) - aOffset + 10;

  // placeholder for checksum
  checkInput += '00';

  // @ts-expect-error TS doesn't know about BigInt
  const checksum = (BigInt('98') - (BigInt(checkInput) % BigInt('97'))).toString();
  return input.substring(2, 4) === checksum;
}

if (ibanInput && ibanError && bicInput) {
  ibanInput.addEventListener('blur', async () => {
    const iban = ibanInput.value.replace(/\s+/g, '');
    if (iban.length < 5 || !validateIBAN(iban)) {
      ibanError.innerHTML = 'Der eingegebene Wert ist keine gültige IBAN!';
      if (submitBtn) submitBtn.disabled = true;
      return;
    }

    // FIXME: This only applies to DE-IBANs. Will we accept other IBANs?
    if (iban.length !== 22) {
      ibanError.innerHTML = 'Nur 22-stellige IBANs werden akzeptiert!';
      if (submitBtn) submitBtn.disabled = true;
      return;
    }

    if (submitBtn) submitBtn.disabled = false;
    ibanError.innerHTML = '';

    if (iban.substring(0, 2) === 'DE') {
      try {
        const response = await fetch('/api/blz/' + iban.substring(4, 12));
        if (response.status === 200) {
          const result = await response.json() as BICInfo;
          bicInput.value = result.bic ?? '';

          if (bankLabel) {
            bankLabel.innerText = result.name ?? 'Bank nicht gefunden';
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
  });

  bicInput.addEventListener('blur', async () => {
    if (!bankLabel) return;

    const bic = bicInput.value.replace(/\s+/g, '');

    try {
      const response = await fetch('/api/bic/' + bic);
      if (response.status === 200) {
        const result = await response.json() as BICInfo;
        bankLabel.innerText = result.name ?? 'Bank nicht gefunden';
      }
    } catch (e) {
      console.error(e);
    }
  });
}

export {}
